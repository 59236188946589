import React from 'react';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';

import Link from '@zd/components/link';
import Button from '@zd/components/button';
import theme from '@zd/theme';

const Div = styled.div`
  background-color: #391685;
  text-align: center;
  width: 100%;
  border-radius: 20px;
  padding: 2rem 1rem;
  display: flex;
  flex-direction: column;
  align-items: center;

  h2 {
    color: #fdf8f3;
    line-height: 42px;
    font-size: 30px;
    font-weight: 600;
    letter-spacing: 0;
    line-height: 42px;
    margin-bottom: 0.25rem;

    ${theme.medias.gteLarge} {
      font-size: 40px;
      line-height: 52px;
    }
  }

  p {
    color: #c8c4d0;
    font-family: Montserrat;
    font-size: 18px;
    letter-spacing: 0;
    line-height: 32px;
    text-align: center;
    margin-bottom: 2rem;
    max-width: 700px;
  }
`;

const InterestedCta = props => {
  const { title, description, ...rest } = props;

  return (
    <Div {...rest}>
      <h2>{title}</h2>
      <p>{description}</p>
      <Link href="/marcar-consulta" title="Marcar consulta">
        <Button color="secondary" title="Marcar consulta">
          Marcar consulta
        </Button>
      </Link>
    </Div>
  );
};

InterestedCta.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
};

InterestedCta.defaultProps = {
  title: 'Ficou interessado?',
  description:
    'Marque uma consulta online ou presencial. Escolha o horário que mais lhe convém, sem atrasos, sem esperas.',
};

export default InterestedCta;
