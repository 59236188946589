import React from 'react';
import PropTypes from 'prop-types';
import BootstrapAccordion from 'react-bootstrap/Accordion';
import BootstrapCard from 'react-bootstrap/Card';
import styled from '@emotion/styled';

import theme from '@zd/theme';

const StyledBootstrapAccordionToggle = styled(BootstrapAccordion.Toggle)`
  &:hover {
    cursor: pointer;
    background: ${theme.colors.lightGrey};
  }
`;

const BaseAccordion = props => {
  const { items, defaultActiveKey } = props;

  return (
    <BootstrapAccordion defaultActiveKey={defaultActiveKey}>
      {items.map((item, i) => (
        <BootstrapCard key={i}>
          <StyledBootstrapAccordionToggle as={BootstrapCard.Header} eventKey={i}>
            {item.title}
          </StyledBootstrapAccordionToggle>
          <BootstrapAccordion.Collapse eventKey={i}>
            <BootstrapCard.Body>{item.body}</BootstrapCard.Body>
          </BootstrapAccordion.Collapse>
        </BootstrapCard>
      ))}
    </BootstrapAccordion>
  );
};

BaseAccordion.propTypes = {
  items: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string,
      body: PropTypes.any,
    })
  ),
  defaultActiveKey: PropTypes.number,
};

BaseAccordion.defaultProps = {
  items: [],
};

export default BaseAccordion;
