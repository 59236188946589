import React from 'react';
import styled from '@emotion/styled';

import PageSEO from '@zd/components/page-seo';
import { Container } from '@zd/components/grid';
import InterestedCta from '@zd/components/interested-cta';
import PageTitle from '@zd/components/page-title';
import Link from '@zd/components/link';
import BackLink from '@zd/components/back-link';
import Accordion from '@zd/components/accordion';

import theme from '@zd/theme';

const StyledAccordion = styled(Accordion)`
  margin-bottom: 1rem;
`;

const StyledBackLink = styled(BackLink)`
  margin-top: 1.5rem;
  margin-bottom: 2.5rem;
`;

export default props => {
  const pageTitle = 'Perguntas Frequentes';

  return (
    <>
      <PageSEO {...props} title={pageTitle} />
      <Container>
        <PageTitle title={pageTitle} />
        <StyledAccordion
          type="split"
          items={[
            {
              title: 'Porque procurar uma consulta online?',
              body: (
                <>
                  Ao lidar com as lutas da vida, ajuda ter alguém com quem conversar. Quer seja
                  stress, ansiedade, depressão ou uma perda súbita, podemos ajudar. Tenha a sua
                  consulta na privacidade e conforto da sua casa. Não precisa de enfrentar tudo
                  sozinha, estamos aqui para ajudá-la!
                </>
              ),
            },
            {
              title: 'Quais as vantagens das consultas online?',
              body: (
                <>
                  Alguns dos benefícios mais comuns associados à terapia online são:
                  <br />
                  <br />
                  <b>Sem limites geográficos:</b> com as consultas online, não existe mais a
                  necessidade de procurar um profissional de saúde mental dentro dos limites da sua
                  cidade.
                  <br />
                  <br />
                  <b>Eficiência:</b> se tem uma vida profissional exigente, é provável que tenha
                  muito pouco tempo disponível. Com as consultas online, pode receber ajuda 24 horas
                  por dia, 7 dias por semana.
                  <br />
                  <br />
                  <b>Estigma:</b> muitas vezes as pessoas não marcam consultas de saúde mental
                  devido ao estigma que as envolve. Na verdade, algumas pessoas que de facto marcam
                  consultas não aparecem para a sessão devido a esta infeliz estigmatização da saúde
                  mental. Com o tratamento online, esse estigma desaparece.
                  <br />
                  <br />
                  <b>Privacidade/Confidencialidade:</b> As consultas online oferecem aos
                  utilizadores total privacidade e confidencialidade.
                  <br />
                  <br />
                  <b>Conveniência:</b> Não precisa de lutar contra o trânsito ou de se preocupar em
                  encaixar mais um compromisso no seu dia. Somos mais acessíveis do que os cuidados
                  de saúde convencionais.
                </>
              ),
            },
            {
              title: 'A terapia online é realmente eficaz?',
              body: (
                <>
                  Absolutamente. Ao longo da última década, numerosos estudos demonstraram o
                  potencial efetivo da consulta online.
                </>
              ),
            },
            {
              title: 'Como funcionam as consultas online?',
              body: (
                <>
                  Com as consultas online temos ajudado também os nossos emigrantes, pacientes com
                  mobilidade reduzida, pacientes que não conseguem sair de casa por variados motivos
                  psicológicos (principalmente estados ansiosos graves ou agorafobia).
                  <br />
                  <br />
                  A procura destas consultas tem ultrapassado todas as expectativas e temos
                  pacientes em três Continentes (Europeu, Africano e Americano).
                  <br />
                  <br />
                  Na nossa opinião, o Psiquiatra deve estar em permanente actualização
                  técnico-científica e participar activamente na busca dos melhores métodos de
                  tratamento baseados nas melhores guidelines nacionais e internacionais, de forma
                  séria e consciente.
                  <br />
                  <br />
                  Esta abordagem também permite dar continuidade ao acompanhamento daqueles
                  pacientes que, por diversos motivos, viajam regularmente ou têm uma vida ocupada,
                  por motivos profissionais ou familiares.
                  <br />
                  <br />
                  A duração das consultas por videoconferência é igual à das consultas presenciais.
                  As consultas por videoconferência serão realizadas através do programa Zoom, que é
                  totalmente seguro.
                  <br />
                  <br />
                  O pagamento é efectuado 24h antes da consulta.
                  <br />
                  <br />
                  Estamos à sua disposição para esclarecer qualquer outra questão que tenha.
                </>
              ),
            },
            {
              title: 'Qual é o preço das consultas online?',
              body: <>A consulta online tem o preço de 78 euros.</>,
            },
            {
              title: 'Como efectuar o pagamento?',
              body: (
                <>
                  O pagamento deverá ser efectuado online através do link{' '}
                  <Link href="/marcar-consulta">https://clinicadaboavista.com/marcar-consulta</Link>
                  .
                </>
              ),
            },
            {
              title: 'Como recebo as minhas receitas, nas consultas online?',
              body: <>As receitas são enviadas para o seu email, no final de cada consulta.</>,
            },
            {
              title: 'O que tratamos?',
              body: (
                <>
                  Ansiedade
                  <br />
                  Depressão
                  <br />
                  Gestão de Stress
                  <br />
                  Luto e Perda
                  <br />
                  Suporte LGBT
                  <br />
                  Trauma e Stress Pós Traumático
                  <br />
                  Problemas de relacionamento
                  <br />
                  Pânico
                  <br />
                  Vícios e Adições
                  <br />
                  Depressão pós-parto
                  <br />
                  Problemas parentais
                  <br />
                  Apoio em Mudanças na vida
                  <br />
                  Problemas de raiva de longo prazo
                  <br />
                  Sensação de estar sobrecarregado
                  <br />
                  Burnout
                  <br />
                  Apoio na superaração de situações stressantes
                  <br />
                  Apoio ao romper barreiras emocionais para alcançar um objetivo
                  <br />
                </>
              ),
            },
            {
              title: 'Quando devo procurar ajuda?',
              body: (
                <>
                  Deve procurar ajuda quando o seu bem-estar e a sua vida estiverem comprometidos
                  (quando um problema estiver a causar-lhe perda, limitações e fracassos).
                  <br />
                  <br />
                  Em casos de luto (até por animais), problemas nas relações amorosas, problemas
                  sexuais, problemas nas relações de amizade e familiares, temperamento explosivo ou
                  impulsivo, compulsões, fobias (medo desproporcional), depressão, stress excessivo,
                  autossabotagem e comportamentos de risco (contra si mesmo, como idéias de suicídio
                  ou contra outras pessoas), ansiedade desproporcional, entre outros.
                  <br />
                  <br />
                  Em momentos importantes da vida e fases de transição. Mudanças de emprego,
                  separações, início da adolescência ou da vida adulta, primeiro filho, ou reforma.
                  Fases de preparação para concursos e para a entrada na Universidade.
                  <br />
                  <br />
                  Todas estas situações e tantas outras semelhantes muitas vezes dão origem a
                  dúvidas, medos, ansiedades e a um sentimento de perda - a perda de um modo de
                  vida, uma forma de ser, uma percepção de si mesmo. Trabalhar as transformações em
                  terapia é vivenciá-las com todo o fortalecimento que elas podem trazer e também
                  abraçar e acolher as possíveis dores.
                </>
              ),
            },
            {
              title: 'Porque procurar uma consulta de saúde mental?',
              body: (
                <>
                  Estamos todos ligados a uma história: a história de quem somos, da família de onde
                  viemos, de como somos e como gostaríamos de ser. No entanto, às vezes a nossa
                  história aprisiona-nos numa visão de nós mesmos que nem sempre nos pertence e que
                  pode ser uma fonte de conflito ou desconforto.
                  <br />
                  <br />
                  Se uma pessoa experimenta um momento de dificuldade não é porque "se tenha
                  quebrado qualquer coisa que precisa de ser ajustado ou substituído". Contudo é
                  importante ter o espaço e o tempo para entender qual é o obstáculo do nosso
                  bem-estar, encontrando consequentemente um outro caminho em que se sinta mais
                  confortável.
                  <br />
                  <br />
                  A consulta de saúde mental é, portanto, um espaço para se apropriar das suas
                  competências e recursos, num contexto acolhedor e não julgador, que o ajudará a
                  assumir um papel ativo e pró-ativo em relação à própria vida e às suas
                  dificuldades.
                  <br />
                  <br />
                  Eu gostaria de ajudá-lo a sentir-se melhor na sua vida, nas suas relações e, mais
                  importante, consigo mesmo.
                </>
              ),
            },
            {
              title:
                'Acha que querer qualidade de vida é um sintoma de personalidade fraca ou doente?',
              body: (
                <>
                  Pode ficar decepcionado se perceber que terá que tratar-se por um período longo.
                  Mas, lembre-se que hipertensos, diabéticos e doentes renais crónicos ficam em
                  tratamento médico para o resto das suas vidas.
                  <br />
                  <br />
                  Se você é um paciente que necessita do seu Psiquiatra lembre-se que você não é
                  louco ou menos capaz (nunca acredite nestas coisas, mesmo se as ouvir), apenas
                  precisa do seu médico de vez em quando, um terapêuta talvez por um tempo e uns
                  comprimidos.
                  <br />
                  <br />A vida é maior do que isto; aceite o tratamento psiquiátrico e tenha amor
                  próprio, na verdade, só com a sua colaboração conseguimos curar a sua dor.
                </>
              ),
            },
            {
              title: 'Qual é a nossa proposta de valor?',
              body: (
                <>
                  Conversar com alguém treinado para ouvir pode ajudar a aliviar o sofrimento e
                  ajudá-lo a encontrar satisfação na vida. Muitas vezes, é o nosso piloto automático
                  que assume os controlos.
                  <br />
                  <br />
                  As nossas consultas ajudam-na a fortalecer-se, com maior autoconsciência para
                  ajudar a quebrar os ciclos de comportamento que podem ter se repetido desde tenra
                  idade.
                  <br />
                  <br />
                  Propomos um espaço de escuta e confronto num contexto caracterizado pela empatia e
                  respeito. Uma viagem a dois para entender o seu sofrimento e reinventar o futuro.
                  Garantimos um acompanhamento com rigor, adaptando métodos e ferramentas a si. É em
                  si mesmo que vai encontrar os recursos!
                  <br />
                  <br />
                  Trata-se de uma combinação entre o cuidado genuíno, habilidades de empatia, e uma
                  abordagem psicanalítica relacional.
                </>
              ),
            },
            {
              title: 'O que é a Psiquiatria?',
              body: (
                <>
                  A Psiquiatria é uma especialidade médica muito exigente.
                  <br />
                  <br />
                  Além de requerer uma actualização científica permanente, uma apurada capacidade de
                  análise dos problemas do paciente, um conhecimento cuidado do seu contexto
                  pessoal, familiar e profissional, é imperativo criar e cultivar uma profunda
                  relação de confiança com o paciente.
                  <br />
                  <br />
                  Uma boa evolução clínica depende sempre, e em grande parte, desta relação de
                  confiança. Médico e paciente deverão ver-se como parceiros duma mesma equipa,
                  compartilhando responsabilidades no sucesso no tratamento.
                </>
              ),
            },
            {
              title: 'O que esperar de uma consulta de Psiquiatria?',
              body: (
                <>
                  Psiquiatria é uma especialidade médica que visa a prevenção, o diagnóstico e o
                  tratamento de perturbações mentais. Através de uma abordagem biopsicossocial que
                  integra aspetos biológicos, psicológicos e sociais, tem por objetivo a compreensão
                  do sofrimento psíquico.
                  <br />
                  <br />
                  Para tal, realiza uma avaliação médica e procura delinear um plano terapêutico
                  que, frequentemente, beneficia de uma intervenção farmacológica.
                  <br />
                  <br />A primeira consulta de Psiquiatria assenta numa entrevista que visa a
                  obtenção de informação relativa à pessoa em sofrimento. O acompanhamento é
                  realizado através de consultas regulares e tem por objetivo avaliar a evolução
                  clínica da doença e realizar os ajustes terapêuticos adequados.
                </>
              ),
            },
            {
              title: 'Porque tomar medicamentos psiquiátricos?',
              body: (
                <>
                  Muitas pessoas sofrem de problemas psiquiátricos caladas, pois pensam que não
                  devem ser medicadas, que o problema se irá “curar sozinho”, que a vida resolve,
                  etc.
                  <br />
                  <br />
                  Ninguém pensaria desta forma com uma perna partida ou com uma garganta inflamada.
                  Em todas as doenças lembramo-nos do médico mas em problemas mentais somos mais
                  displicentes e obedecemos ao nosso preconceito, esquecendo o nosso bem estar. Só
                  esta consideração já é um motivo para o tratamento: doenças mentais são doenças e
                  não desaparecem sozinhas.
                  <br />
                  <br />
                  Você não tem culpa por ter ficado doente, mas tem responsabilidade por se recusar
                  a tratar e melhorar. Não tem culpa por ter errado ou falhado num momento de
                  doença, mas tem responsabilidade se persistir no erro. O tratamento psiquiátrico
                  não é um sinal de fraqueza ou loucura, é um sinal de luta, crítica e autoestima.
                </>
              ),
            },
            {
              title: 'Qual a duração de tratamento?',
              body: (
                <>
                  A duração do tratamento é altamente variável e depende de muitos fatores,
                  incluindo os objetivos pessoais do cliente, a sua experiência com a terapia e os
                  desafios que se terão de gerir.
                </>
              ),
            },
            {
              title: 'Quais as vantagens em fazer psicoterapia?',
              body: (
                <>
                  A Psicoterapia é uma ferramenta fantástica para melhorar a sua qualidade de vida.
                  Vai desenvolver diversas competencias, entre as quais:
                  <br />
                  <br />
                  <b>Auto-conhecimento</b>
                  <br />
                  Com a Psicoterapia aprende a conhecer-se muito bem, o que faz com que perceba
                  melhor os seus sentimentos e emoções, aprendendo desta forma a lidar muito melhor
                  com eles (medo, culpa, raiva, tristeza, carência...).
                  <br />
                  <br />
                  <b>Capacidade de comunicação</b>
                  <br />
                  A sua capacidade de comunicação aumenta, o que faz com que a sua vida seja mais
                  fluida em todos os aspectos. O seu sucesso profissional e pessoal está intimamente
                  relacionado com a capacidade de comunicação que você tem, com os outros e consigo
                  mesma.
                  <br />
                  <br />
                  <b>Resolução de problemas e tomada de decisões</b>
                  <br />
                  A sua tomada de decisões torna-se mais rápida, segura e clara. Isto porque, não só
                  o seu pensamento e as suas emoções se tornam mais claros e leves, mas também
                  começam a trabalhar em conjunto para um objectivo comum. Desta forma deixa de
                  viver em contradição e indecisão e deixa de boicotar a sua própria vida.
                  <br />
                  <br />
                  <b>Auto-estima</b>
                  <br />
                  A sua auto-estima aumenta exponencialmente. Aprende a aceitar e a lidar com o seu
                  lado bom e com o seu lado mau. Aprende a gostar de estar na sua própria companhia
                  e a estar bem consigo própria, mesmo estando sozinha. Aprende a olhar para os seus
                  problemas de forma objectiva e clara e aprende a tomar as decisões certas, para o
                  bem da sua vida.
                  <br />
                  <br />
                  <b>Relações Interpessoais</b>
                  <br />
                  As relações que tem com os outros: família, amigos ou companheiros vão tornar-se
                  muito mais harmoniosas e prazerosas. Isto porque você aprende a lidar com as suas
                  carências e deixa de pôr esse peso nas relações. Deixa de esperar que alguém a
                  faça feliz, porque você própria já sabe como ser feliz. Qualquer relação que venha
                  a ter torna-se um bónus na sua vida, e não um peso, um problema, uma dependência
                  ou uma guerra.
                  <br />
                  <br />
                  <b>Controlo e tolerância ao stress</b>
                  <br />
                  Com a Psicoterapia, a sua ansiedade deixa de ser sua inimiga, para se tornar a sua
                  melhor aliada. É impossível não ter ansiedade, mas é possível usar essa ansiedade
                  em seu proveito. Desta forma você deixa de ter episódios de: ansiedade extrema e
                  pânico; procrastinação; fuga e evitamento de situações ansiogénicas, para passar a
                  usar essa ansiedade como um motor de energia, para se tornar mais produtiva, e um
                  mecanismo de alerta para que saiba que caminhos e decisões deve ou não tomar.
                  <br />
                  <br />
                  <b>Capacidade de amar</b>
                  <br />
                  Aprende a deixar de associar o amor à sua falta ou carência e passa a experienciar
                  esse sentimento na sua plenitude, livre de angústias, medos, culpas, raivas ou
                  carências.
                  <br />
                  <br />
                  Cuide de si, invista na sua vida! Marque ainda hoje a sua primeira consulta e veja
                  como se sente no final.
                </>
              ),
            },
            {
              title: 'O que é a psicoterapia psicanalítica?',
              body: (
                <>
                  É um método psicoterapêutico que tem como objetivo promover o autoconhecimento,
                  desenvolver a capacidade de pensar e proporcionar um conjunto de transformações
                  psíquicas. Com o intuito de ajudar a resolver as dificuldades e o sofrimento
                  individual, procura uma maior compreensão sobre a natureza e origem dos problemas.
                  Desta forma, centra-se na análise da vida emocional complexa, e da sua dimensão
                  inconsciente, como uma parte fundamental da condição humana.
                  <br />
                  <br />
                  Por vezes, o ser humano repete comportamentos destrutivos, repete relações
                  disfuncionais e insatisfatórias e inibe o desenvolvimento emocional, criativo e a
                  realização profissional. Este tratamento é indicado para quem se sente aprisionado
                  a problemas psíquicos recorrentes que impedem o desenvolvimento do seu potencial e
                  de um sentimento de bem-estar junto dos seus companheiros, familiares e amigos.
                  <br />
                  <br />
                  O tratamento psicanalítico consiste na realização de sessões regulares entre o
                  paciente e o psicanalista. A continuidade, frequência, estabilidade e
                  confidencialidade, inerente ao espaço analítico, permitem que paciente e
                  psicanalista possam compreender o que ocorre a um nível mais profundo.
                  <br />
                  <br />O tempo é um fator determinante no tratamento psicanalítico, uma vez que a
                  psicanálise não oferece soluções rápidas. O tempo, juntamente com a atenção e o
                  pensamento, possibilitam mudanças duradouras e autênticas, mesmo quando se
                  verificam problemáticas emocionais graves.
                </>
              ),
            },
          ]}
        />
        <InterestedCta />
        <br />
        <StyledBackLink />
      </Container>
      {/*<Instagram /> */}
    </>
  );
};
