import React from 'react';
import PropTypes from 'prop-types';
import BootstrapAccordion from 'react-bootstrap/Accordion';
import BootstrapCard from 'react-bootstrap/Card';
import styled from '@emotion/styled';

import theme from '@zd/theme';

const StyledBootstrapAccordion = styled(BootstrapAccordion)`
  margin-bottom: 0.5rem;
`;

const StyledBootstrapAccordionToggle = styled(BootstrapAccordion.Toggle)`
  margin: 0;
  background: linear-gradient(141.26deg, #ffe8d0 0%, #f8dbbe 100%);

  &:hover {
    cursor: pointer;
    background: linear-gradient(141.26deg, #ffe8d0 0%, #f8dbbe 100%);
  }
`;

const SplitAccordion = props => {
  const { items, defaultActiveKey, ...rest } = props;

  return (
    <div {...rest}>
      {items.map((item, i) => {
        return (
          <StyledBootstrapAccordion defaultActiveKey={defaultActiveKey} key={i}>
            <BootstrapCard>
              <StyledBootstrapAccordionToggle as={BootstrapCard.Header} eventKey={i}>
                {item.title}
              </StyledBootstrapAccordionToggle>
              <BootstrapAccordion.Collapse eventKey={i}>
                <BootstrapCard.Body>{item.body}</BootstrapCard.Body>
              </BootstrapAccordion.Collapse>
            </BootstrapCard>
          </StyledBootstrapAccordion>
        );
      })}
    </div>
  );
};

SplitAccordion.propTypes = {
  items: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string,
      body: PropTypes.any,
    })
  ),
  defaultActiveKey: PropTypes.number,
};

SplitAccordion.defaultProps = {
  items: [],
};

export default SplitAccordion;
