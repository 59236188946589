import React from 'react';
import PropTypes from 'prop-types';

import BaseAccordion from './BaseAccordion';
import SplitAccordion from './SplitAccordion';

const Accordion = props => {
  const { type, ...rest } = props;

  if (type === 'split') {
    return <SplitAccordion {...rest} />;
  }

  return <BaseAccordion {...rest} />;
};

Accordion.propTypes = {
  items: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string,
      body: PropTypes.any,
    })
  ),
  type: PropTypes.oneOf(['base', 'split']),
  defaultActiveKey: PropTypes.number,
};

Accordion.defaultProps = {
  items: [],
  type: 'base',
};

export default Accordion;
